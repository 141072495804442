import React from "react"
import {graphql, useStaticQuery, Link} from "gatsby"
import Layout from "../components/layout"
import _ from "lodash"

import Region1 from "../images/1.svg"
import Region2 from "../images/2.svg"
import Region3 from "../images/3.svg"
import Region4 from "../images/4.svg"
import Region5 from "../images/5.svg"

export default function IndexPage() {

  const q = useStaticQuery(graphql`
    query Regions {
      allRegionsCsv {
        nodes {
          Region
          Town
        }
      }
    }
  `)

  const reg2Image = {
    'Region 1': Region1,
    'Region 2': Region2,
    'Region 3': Region3,
    'Region 4': Region4,
    'Region 5': Region5,
  }

  const reg2name = {
    'Region 1': 'Southwest CT',
    'Region 2': 'South Central CT',
    'Region 3': 'Eastern CT',
    'Region 4': 'North Central CT',
    'Region 5': 'Northwestern CT'
  }

  const regions = _.groupBy(q.allRegionsCsv.nodes, 'Region');

  return <Layout>

    <p className="f3 measure-wide">
      Regional interactive data stories show cross-sector data that describe general
      characteristics of each of five DMHAS regions.
    </p>
      
    <section className="flex flex-wrap pt5">
      
      {Object.keys(regions).map(region =>

        <Link
          to={'region/' + region.split(' ')[1]}
          className="w-100 w-50-m w-third-l pa3 link bg-animate hover-bg-near-white bl bw1 b--near-white mb4"
          key={region}
        >

          <h2 className="black-80 f3 mt0">{reg2name[region]}</h2>

          <img src={reg2Image[region]} alt="Region 1 on the map" className="w-60 center db"/>

          <p className="lh-copy black-80">
            <span style={{color: '#589b3c'}}>Includes</span> {regions[region].map(x => x.Town).join(', ')}.
          </p>

        </Link>

      )}


    </section>

  </Layout>
}
